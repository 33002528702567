form {
	.cols-1,
	.cols-2,
	.cols-3,
	.cols-4 {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
	}
}

fieldset {
	margin: 0;
	padding: 0;
	border: none;
}

.animated-label {
	position: relative;
	width: 100%;
	@include clearfix;

	&.focused {
		label {
			top: -1.125rem;
			font-size: 80%;
		}
	}

	&.message-container {
		height: 100%;
	}

	&.info {
		input[type="email"],
		input[type="number"],
		input[type="search"],
		input[type="text"],
		input[type="tel"],
		input[type="url"],
		input[type="password"],
		textarea,
		select {
			padding-right: 2rem;
		}

		label {
			padding-right: 2rem;
		}

		.info-hover {
			position: absolute;
			top: 0.875rem; right: 0.5rem;
			width: 1rem;
			height: 1rem;
			cursor: pointer;
			opacity: 0.5;
			z-index: 1;

			&.active {
				opacity: 1;

				span {
					visibility: visible;
					opacity: 1;
				}
			}

			span {
				font-family: $font;
				position: absolute;
				bottom: calc(100% + 1rem); left: 50%;
				background: $white;
				width: 12.125rem;
				padding: 0.625rem 0.5rem;
				border-radius: 0.25rem;
				box-shadow: 1px 2px 10px rgba($black, 0.25);
				transform: translate(-50%, 0);
				transition: all 0.3s ease;
				text-align: center;
				visibility: hidden;
				line-height: 1.4;
				font-size: 87.5%;
				opacity: 0;

				&:before {
					content: '';
					position: absolute;
					top: 100%; left: 50%;
					transform: translate(-50%, -1%);
					border: 7px solid transparent;
					border-top-color: $white;
				}
			}
		}
	}

	label {
		position: absolute;
		top: 0; left: 0;
		width: 100%;
		margin: 0.25rem 0;
		padding: 0.375rem 0.625rem;
		border: 0 none;
		transition: all 0.25s ease;
		line-height: 1.25;
		font-weight: 300;
		text-align: left;
		font-size: 112.5%;
		cursor: text;
		color: $medium-grey;
		z-index: 1;
	}

	.selectized ~ label {
		cursor: pointer;
	}

	.selectized[disabled] ~ label {
		cursor: revert;
	}
}

label {
	display: block;
	width: 100%;
	font-weight: 400;
	color: $medium-grey;

	&.required {
		&::after {
			content: ' *';
			color: $negative;
		}
	}
}

::placeholder {
	font-weight: 200;
	color: $medium-grey;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
	display: block;
	background: transparent;
	width: 100%;
	height: auto;
	margin: 0 0 1.5rem;
	padding: 0.625rem;
	border: 0 none;
	border-bottom: 1px solid $black;
	transition: all 0.25s ease;
	font-weight: 300;
	font-size: 112.5%;
	line-height: 1;
	resize: vertical;
	outline: none;
	color: $black;

	&:focus {
		border-color: $primary;

		~ label {
			top: -1.125rem;
			font-size: 80%;
		}
	}

	&[disabled],
	&[readonly] {
		background: $light-grey;
		border-bottom: 0 none;

		~ label {
			background: $light-grey;
			cursor: revert;
		}
	}

	&.date-picker {
		cursor: text;
	}

	&.error {
		margin-bottom: 0;
		border-color: $negative;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;

		&:focus {
			border-color: $negative;
		}
	}
}

input[type="file"] {
	background: rgba($primary, 0.5);
	width: 100%;
	margin: 0 0 1.5rem;
	padding: 0.75rem 1rem;
}

textarea {
	line-height: 1.5;
}

select {
	text-overflow: ellipsis;
	appearance: none;
	clear: left;
}

.selectized {
	&.error {
		+ .selectize-control {
			margin-bottom: 0;

			&.single {
				.selectize-input {
					&.input-active {
						border-color: $negative;
					}
				}
			}

			.selectize-input {
				border-color: $negative;
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;

				&:focus {
					border-color: $negative;
				}
			}
		}
	}
}

.selectize-control {
	width: 100%;
	margin: 0 0 1.5rem;

	&.single {
		.selectize-input {
			&.input-active {
				display: block;
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
				border-color: $primary;
			}
		}
	}

	&.multi {
		.selectize-input {
			transition: none;

			&.has-items {
				padding: 11px 1rem;
			}

			.item {
				margin-bottom: 2px;
			}

			input,
			.item {
				// font-size: 85%;
			}
		}
	}

	.selectize-input {
		display: block;
		background: transparent !important;
		padding: 0.625rem;
		border-radius: 0;
		border: 0 none;
		border-bottom: 1px solid $black;
		transition: all 0.25s ease;
		box-shadow: none;
		line-height: 1.25;
		font-size: 112.5%;
		// font-size: 100%;

		&.dropdown-active {
			&::before {
				background: $primary;
			}
		}

		&.disabled {
			background: $light-grey;
			border-bottom: 0 none;
			opacity: 1;

			&::after  {
				content: none;
			}
		}

		input {
			border: none;
			font-size: 100%;
		}

		input,
		.item {
			line-height: 1;
			font-weight: 300;
		}
	}
}

.selectize-dropdown {
	border-color: $primary;

	.active {
		background-color: rgba($primary, 0.15);
	}

	.option {
		padding: 0.5rem 0.75rem;
		font-size: 123%;
	}
}

.radiochecks {
	position: relative;
	margin: 0 0.5rem 1.5rem;

	&.cols-2 {
		display: flex;
		width: 100%;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&.inline {
		label {
			display: inline-block;
			margin-right: 1rem;
		}
	}

	label {
		position: relative;
		clear: left;
	}

	input {
		display: inline-block;
		width: auto;
	}

	span {
		display: block;
		padding-left: 1.25rem;
	}

	+ span.error {
		margin-top: 0.5rem;
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
	}
}


// Checkbox (add class="checkbox" to the checkbox type input)

.checkbox {
	position: absolute;
	top: 5.5px; left: 3.5px;
	opacity: 0;
	z-index: -1;

	&:hover {
		& + label {
			&::before {
				background: $primary;
			}
		}
	}

	&:focus {
		& + label {
			&::before {
				box-shadow: 0 0 0 2px $white,
							0 0 0 4px $secondary;
			}
		}
	}

	&:checked {
		& + label {
			&::before {
				background: $primary;
				border-color: $primary;
			}

			&::after {
				content: '';
				position: absolute;
				top: 11px; left: 5px;
				background: $white;
				width: 2px;
				height: 2px;
				box-shadow: 2px 0 0 $white,
							4px 0 0 $white,
							4px -2px 0 $white,
							4px -4px 0 $white,
							4px -6px 0 $white,
							4px -8px 0 $white;
				transform: rotate(45deg);
			}
		}
	}

	&:disabled {
		& + label {
			cursor: auto;
			color: $dark-grey;

			&::before {
				background: $light-grey;
				box-shadow: none;
			}

			&::after {
				background: $medium-grey;
				box-shadow: 2px 0 0 $medium-grey,
							4px 0 0 $medium-grey,
							4px -2px 0 $medium-grey,
							4px -4px 0 $medium-grey,
							4px -6px 0 $medium-grey,
							4px -8px 0 $medium-grey;
			}
		}
	}

	& + label {
		display: block;
		position: relative;
		margin: 0.5rem 0 0;
		padding: 0 0 0 1.75rem;
		transition: all 0.25s ease;
		font-weight: 300;
		cursor: pointer;
		color: $black;

		&::before {
			content: '';
			display: inline-block;
			position: absolute;
			top: 0.125rem; left: 0;
			background: rgba($black, 0.1);
			width: 1.25rem;
			height: 1.25rem;
			transition: all 0.25s ease;
			vertical-align: text-top;
		}
	}
}


// Radio Input

[type="radio"] {
	position: absolute;
	opacity: 0;
	z-index: -1;

	&:hover {
		& + label {
			&::after {
				top: 4px; left: 0;
				width: 19px;
				height: 19px;
			}
		}
	}

	&:focus {
		& + label {
			&::before {
				box-shadow: 0 0 0 2px $white,
							0 0 0 4px $secondary;
			}
		}
	}

	&:checked {
		& + label {
			&::before {
				background: $primary;
			}

			&::after {
				top: 9px; left: 5px;
				background: $white;
				width: 9px;
				height: 9px;
			}
		}
	}

	&:disabled {
		& + label {
			cursor: auto;
			color: $dark-grey;

			&::before {
				background: $light-grey;
				box-shadow: none;
			}

			&::after {
				background: $medium-grey;
			}
		}
	}

	& + label {
		display: block;
		position: relative;
		margin: 0.5rem 0 0;
		padding: 0 0 0 1.75rem;
		transition: all 0.25s ease;
		font-weight: 300;
		cursor: pointer;
		color: $black;

		&::before {
			content: '';
			position: absolute;
			top: 0.25rem; left: 0;
			background: rgba($black, 0.1);
			width: 19px;
			height: 19px;
			border-radius: 100%;
		}

		&::after {
			content: '';
			position: absolute;
			top: 14px; left: 10px;
			background: $primary;
			width: 1px;
			height: 1px;
			transition: all 0.25s ease;
			border-radius: 100%;
		}
	}
}


// Flatpickr

.flatpickr-current-month span.cur-month:hover,
.numInputWrapper:hover {
	background: transparent;
}

.flatpickr-day {
	line-height: 37px;

	&.selected {
		background: $primary;
		border-color: $primary;
		color: $black;

		&:hover {
			background: $secondary;
			border-color: $secondary;
		}
	}
}

.grecaptcha-badge {
	// display: none !important;
}


// Responsive

@media only screen and (min-width: 769px) {
	form {
		.cols-1 {
			width: 100%;
		}

		.cols-2 {
			width: calc((100% - 1rem) / 2);
		}

		.cols-3 {
			width: calc((100% - (1rem * 2)) / 3);
		}

		.cols-4 {
			width: calc((100% - (1rem * 3)) / 4);
		}
	}
}
