.actions {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	&.start {
		justify-content: flex-start;
	}

	&.end {
		flex-direction: column-reverse;
		justify-content: flex-end;
	}

	button,
	.button {
		width: 100%;
		max-width: 20rem;
		margin: 0.5rem 0;
	}
}

button,
.button {
	@extend %bv_hidden;

	display: inline-block;
	position: relative;
	background: $primary;
	padding: $btn-padding;
	border-radius: 10rem;
	border: 2px solid $primary;
	transition: all 0.25s ease;
	text-transform: uppercase;
	text-decoration: none;
	line-height: inherit;
	text-align: center;
	font-weight: 500;
	font-size: 87.5%;
	appearance: none;
	overflow: hidden;
	cursor: pointer;
	color: $white;
	z-index: 1;

	&::before {
		content: '';
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		background: $white;
		border-radius: 10rem;
		width: auto;
		height: auto;
		transform: translate(-100%, 0);
		transition: all 0.25s ease;
		z-index: -1;
	}

	&::after {
		content: none;
	}

	&:hover {
		color: $secondary;

		&::before {
			transform: translate(0, 0);
		}
	}

	&.primary {
		font-size: 100%;
	}

	&.secondary {
		background: $secondary;
		border-color: $secondary;
		font-size: 87.5%;
		color: $white;

		&:hover {
			border-color: $secondary;
			color: $secondary;
		}
	}

	&.negative {
		background: $negative;
		border-color: $negative;
		color: $white;

		&:hover {
			color: $negative;
		}
	}

	&.action {
		background: $dark-grey;
		border-color: transparent;
		color: $white;

		&:hover {
			color: $black;
		}
	}

	&.hollow {
		background: transparent;
		color: $primary;

		&:before {
			background: $primary;
		}

		&:hover {
			color: $white;
		}

		&.secondary {
			color: $secondary;

			&::before {
				background: $secondary;
			}

			&:hover {
				color: $white;
			}
		}

		&.negative {
			color: $negative;

			&::before {
				background: $negative;
			}

			&:hover {
				color: $white;
			}
		}

		&.action {
			border-color: $dark-grey;
			color: $dark-grey;

			&::before {
				background: $dark-grey;
			}

			&:hover {
				color: $white;
			}
		}
	}

	&.icon-start {
		padding: 0.5rem 1rem;

		i {
			margin-right: 0.5rem;
		}
	}

	&.icon-end {
		padding: 0.5rem 1rem;

		i {
			margin-left: 0.5rem;
		}
	}
}


// Light Gallery Buttons

.lg-toolbar {
	.lg-close {
		&::before {
			content: none;
		}
	}
}

.lg-prev,
.lg-next {
	transition: all 0.25s ease;

	&::before {
		content: none;
	}
}

.lg-next {
	&::after {
		content: '\e095';
	}
}


// Responsive

@media only screen and (min-width: 569px) {
	.actions {
		&.end {
			flex-direction: initial;
		}

		button,
		.button {
			width: auto;
			max-width: 100%;
			margin: 0.5rem;
		}
	}
}
