// Typography

$font: 'Barlow', Arial, Helvetica, sans-serif;
$font-semi: 'Barlow Semi Condensed', Arial, Helvetica, sans-serif;
$font-condensed: 'Barlow Condensed', Arial, Helvetica, sans-serif;
$font-awesome: 'Font Awesome 5 Pro';


// Settings

$max-width: 1312px;
$node-spacing: 1.875rem;
$btn-padding: 0.5rem 2rem;


// Colours

$black: #000000;
$dark-grey: #292c3d;
$medium-grey: #999999;
$light-grey: #f7f7f7;
$white: #ffffff;

$primary: #FF0059;
$secondary: #273892;
$tertiary: #214391;
$highlight: #db2323;

$positive: #0ab04b;
$positive-text: #2bc70c;
$negative: #e63737;

$label: #1c4c56;
$input-bg: #ffffff;
