@import '~normalize.css/normalize.css';
@import 'partials/_variables.scss';
@import 'partials/_mixins.scss';
@import 'partials/_messages.scss';
@import 'partials/_buttons.scss';
@import 'partials/_slider.scss';
@import 'partials/_forms.scss';


// Global Styles

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	font-family: $font;
	position: relative;
	color: $secondary;
	-webkit-overflow-scrolling: touch;

	* {
		box-sizing: border-box;
	}
}

.centraliser {
	@include centraliser;
}

.page-overlay {
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	background: rgba($black, 0.7);
	transition: all 0.25s ease;
	visibility: hidden;
	opacity: 0;

	&.active,
	&.mobile-nav {
		visibility: visible;
		opacity: 1;
	}

	&.active {
		z-index: 4;
	}

	&.mobile-nav {
		z-index: 3;
	}

	&.fixed {
		position: fixed;
	}
}

a {
	transition: all 0.25s ease;
	text-decoration: none;
}

img {
	display: block;
	max-width: 100%;
	max-height: 100%;
}


// Header

header {
	position: relative;
	background: $white;
	box-shadow: 0 2px 5px rgba($black, 0.15);
	z-index: 4;

	.centraliser {
		display: flex;
		padding: 1.25rem;
		justify-content: flex-end;
		align-items: center;

		&::before {
			content: '';
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			background: $white;
			z-index: 3;
		}
	}

	#logo {
		display: block;
		width: 100%;
		max-width: 3.25rem;
		height: 2.5rem;
		margin: 0 auto 0 0;
		z-index: 3;

		&::before,
		&::after {
			content: none;
		}
	}

	nav {
		position: absolute;
		top: 5rem; right: 0;
		background: $white;
		width: 100%;
		max-width: 22.5rem;
		margin: 0 auto;
		transform: translate(0, -100%);
		transition: all 0.25s ease;
		text-align: left;
		z-index: 2;

		&.active {
			box-shadow: 0 2px 5px rgba($black, 0.15);
			transform: translate(0, 0);
		}

		ul {
			padding: 0.5rem 0;
			list-style: none;

			li {
				position: relative;
				margin: 0;

				a {
					display: block;
					position: relative;
					padding: 0.5rem 1.25rem;
					text-transform: uppercase;
					font-weight: 700;
					font-size: 88%;
					z-index: 1;
					color: $secondary;

					&::before {
						content: '';
						position: absolute;
						top: 0; bottom: 0; left: 0;
						background: $primary;
						width: 0;
						transition: all 0.25s ease;
						z-index: -1;
					}

					&:hover {
						color: $white;

						&::before {
							width: 100%;
						}

						+ button i {
							color: $white;
						}
					}
				}

				button {
					display: flex;
					position: absolute;
					top: 0; right: 0.25rem;
					background: transparent;
					width: 2rem;
					height: 2rem;
					padding: 0;
					border: 0 none;
					justify-content: center;
					align-items: center;
					transition: all 0.25s ease;
					font-size: 100%;
					z-index: 1;

					&::before {
						content: '';
						position: absolute;
						top: 50%; left: 50%;
						background: $primary;
						width: 0;
						height: 0;
						border-radius: 10rem;
						transform: translate(-50%, -50%);
						transition: all 0.25s ease;
						opacity: 1;
						z-index: -1;
					}

					&:hover {
						&::before {
							width: 2rem;
							height: 2rem;
							transform: translate(-50%, -50%);
							opacity: 1;
						}

						i {
							color: $white;
						}
					}

					&.active {
						i {
							transform: rotate(180deg);
						}
					}

					i {
						width: 1rem;
						height: 1rem;
						transition: all 0.25s ease;
						color: $secondary;
					}
				}

				ul {
					display: none;
					padding: 0 0 0.5rem;

					li {
						a {
							padding: 0.25rem 1.25rem;
							text-transform: none;
							font-weight: 400;
						}
					}
				}
			}
		}
	}

	.icon {
		display: flex;
		width: 2.5rem;
		height: 2.5rem;
		padding: 0.5rem;
		border-radius: 3px;
		border: 0 none;
		justify-content: center;
		align-items: center;
		font-size: 115%;
		z-index: 3;
		color: $secondary;

		&:hover {
			background: $primary;
			color: $white;
		}
	}

	.search {
		background: transparent;

		&::before {
			content: none;
		}

		&:hover {
			background: $secondary;
		}
	}

	#account {
		margin-left: 0.25rem;
	}

	.mobile-nav {
		position: relative;
		background: transparent;
		margin-left: 0.25rem;

		&::before,
		&::after {
			content: none;
		}

		span,
		span::before,
		span::after {
			display: block;
			position: absolute;
			background: $primary;
			width: 1.375rem;
			height: 3px;
		}

		span {
			top: 19px; left: 9px;
			transition: transform 150ms ease,
						background 0.25s ease;

			&::before {
				content: '';
				top: -8px; left: 0;
				transition: top 150ms ease 0.125s,
							opacity 150ms ease 0.125s,
							background 0.25s ease;
			}

			&::after {
				content: '';
				bottom: -8px; left: 0;
				transition: bottom 150ms ease 0.125s,
							transform 150ms ease,
							background 0.25s ease;
			}
		}

		&:hover {
			background: $primary;

			span,
			span::before,
			span::after {
				background: $white;
			}
		}

		&.active {
			span {
				transform: rotate(45deg);
				transition: transform 150ms ease 0.125s,
							background 0.25s ease;

				&::before {
					top: 0;
					opacity: 0;
					transition: top 150ms ease,
								opacity 150ms ease,
								background 0.25s ease;
				}

				&::after {
					bottom: 0;
					transform: rotate(-90deg);
					transition: transform 150ms ease 0.125s,
								background 0.25s ease;
				}
			}
		}
	}
}

.landing-page {
	nav,
	.mobile-nav {
		display: none;
	}
}


// Search

#search-form,
#search {
	button {
		position: absolute;
		top: 50%; right: 3rem;
		background: transparent;
		padding: 0.75rem;
		border: 0 none;
		border-radius: 3px;
		transform: translate(0, -50%);
		font-size: 125%;

		&::before {
			content: none;
		}

		&:hover {
			background: $primary;
			color: $white;
		}

		&.close {
			top: 1rem; right: 1rem;
			transform: none;
		}

		i {
			display: block;
			width: 1.25rem;
			height: 1.25rem;
		}
	}
}

#search {
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	background: rgba($black, 0.85);
	padding: 3rem;
	transition: all 0.25s ease;
	visibility: hidden;
	opacity: 0;
	z-index: 4;

	&.active {
		visibility: visible;
		opacity: 1;
	}

	#search-form {
		position: absolute;
		top: 50%; left: 50%;
		transform: translate(-50%, -50%);

		label {
			color: $light-grey;
		}

		input {
			border-bottom-color: $white;
			color: $white;
		}
	}

	button {
		color: $white;
	}
}

#search-form {
	position: relative;
	width: 100%;
	max-width: 41.25rem;
	padding: 0 3rem;

	label {
		margin: 0.75rem 0.5rem;
		padding: 0.25rem;
		border: 0 none;
		font-size: 125%;
	}

	.focused {
		label {
			top: -1.75rem;
			font-size: 100%;
		}
	}

	input {
		background: none;
		margin: 0;
		padding: 1rem 3.5rem 1rem 0.75rem;
		border: 0 none;
		border-bottom: 1px solid $black;
		font-size: 125%;
	}
}

#search-results {
	h3 {
		margin-bottom: 2rem;
		text-align: center;
	}
}

.search-results {
	max-width: 40rem;
	margin: 0 auto;
	padding: 0;
	list-style: none;

	.search-result {
		margin: 0;
		padding: 0;

		a {
			display: block;
			background: transparent;
			padding: 0.75rem 1.25rem 0.875rem;
			transition: all 0.25s ease;

			&:hover {
				background: $primary;
				color: $white;
			}

			b {
				font-weight: 500;
				font-size: 118.75%;
			}
		}
	}
}


// Footer

footer {
	background: $dark-grey;

	.centraliser {
		display: flex;
		padding: 3rem 2rem;
		flex-direction: column;
		color: $white;

		@include clearfix;
	}

	a {
		color: $white;

		&::before,
		&::after {
			content: none;
		}

		&:hover {
			color: $primary;
		}
	}

	.phone,
	.email {
		display: flex;
		position: relative;
		background: $primary;
		width: 100%;
		max-width: 16rem;
		margin: 0 auto 2rem;
		border-radius: 10rem;
		border: 2px solid $primary;
		align-items: center;
		font-size: 175%;
		color: $white;
		z-index: 1;
		order: 1;

		&::before {
			content: '';
			position: absolute;
			top: 0; left: 0;
			background: $dark-grey;
			width: 3.25rem;
			height: 3.25rem;
			transition: all 0.25s ease;
			border-radius: 10rem;
			z-index: -1;
		}

		&:hover {
			&::before {
				width: 100%;
			}
		}

		i {
			display: flex;
			background: $dark-grey;
			width: 3.25rem;
			height: 3.25rem;
			margin-right: 1rem;
			border-radius: 10rem;
			justify-content: center;
			align-items: center;
			font-size: 75%;
			z-index: 0;
			color: $primary;
		}
	}

	.email {
		max-width: 20rem;
		margin-bottom: 3rem;
		font-weight: 500;
		font-size: clamp(0.875rem, -0.25rem + 5vw, 1rem);

		i {
			font-size: 1.5rem;
		}
	}

	.social {
		display: flex;
		margin: 0 auto 3rem;
		justify-content: center;
		list-style: none;
		order: 2;

		li {
			+ li {
				margin-left: 1.25rem;
			}

			a {
				display: block;
				line-height: 1.875rem;
				font-size: 215%;

				i {
					&::before {
						display: block;
						max-width: 1.875rem;
						line-height: 1.875rem;
					}
				}
			}
		}
	}

	nav.footer {
		margin: 0 auto 3rem;
		text-align: center;
		order: 3;

		ul {
			list-style: none;

			li {
				a {
					display: inline-block;
					padding: 0.5rem;
				}
			}
		}
	}

	.legal {
		margin: 0 auto 3rem;
		text-align: center;
		line-height: 1.9;
		font-weight: 300;
		font-size: 75%;
		order: 4;

		.separator {
			margin: 0.5rem;
		}

		.copy {
			display: block;
		}
	}

	.top {
		position: relative;
		background: transparent;
		width: 2.5rem;
		height: 2.5rem;
		margin: 0 auto;
		padding: 10px 10px 12px 12px;
		border-radius: 10rem;
		border: 1.5px solid $primary;
		line-height: 1;
		font-size: 100%;
		color: $primary;
		z-index: 0;
		order: 5;

		&::before {
			content: '';
			position: absolute;
			top: 50%; left: 50%;
			background: $primary;
			width: 0;
			height: 0;
			border-radius: 10rem;
			transform: translate(-50%, -50%);
			transition: all 0.5s ease;
			opacity: 0;
			z-index: -1;
		}

		&:hover {
			border-color: $primary;
			color: $dark-grey;

			&::before {
				width: 100%;
				height: 100%;
				opacity: 1;
			}
		}

		i {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 1rem;
			height: 1rem;
			transform: translate(-50%, -50%);
		}
	}
}


// Page Header

.page-header {
	display: flex;
	position: relative;
	background: transparent url('/img/placeholder-page-header.jpg') no-repeat center center;
	background-size: cover;
	padding: 5rem 1.25rem;
	// border-bottom: 5px solid $primary;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: column;

	&::before {
		content: '';
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		background: rgba($black, 0.5);
		z-index: 1;
	}

	&.dealers {
		&::before {
			background-color: rgba($primary, 0.5);
		}
	}

	&.employer-employee {
		&::before {
			background-color: rgba($secondary, 0.5);
		}
	}

	.client-logo {
		position: absolute;
		top: 50%; left: 50%;
		width: auto;
		max-width: 12.5rem;
		height: auto;
		max-height: 12.5rem;
		transform: translate(-50%, -50%);
		z-index: 1;
	}

	h1 {
		font-family: $font-semi;
		margin: 0;
		text-shadow: 1px 1px 2px rgba($black, 0.25);
		text-transform: uppercase;
		letter-spacing: 0.5px;
		font-weight: 500;
		font-size: clamp(2.25rem, 2rem + 1.111vw, 3rem);
		color: $white;
		z-index: 1;
	}

	p {
		margin: 0.75rem 0 0;
		text-shadow: 1px 1px 2px rgba($black, 0.25);
		font-weight: 100;
		font-size: 150%;
		color: $white;
		z-index: 1;
	}

	.date {
		margin: 0 0 1.25rem;
		font-size: 87.5%;
	}

	.more {
		position: absolute;
		bottom: 0.625rem; left: 50%;
		background: transparent;
		width: 2.5rem;
		height: 2.5rem;
		padding: 0;
		border: 0 none;
		animation: fadeInDown 3s infinite 3s;
		transform: translate(-50%, 0);
		font-size: 100%;
		color: $white;
		z-index: 1;

		&::before {
			content: none;
		}
	}

	.page-slider {
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;

		.slick-list {
			width: 100%;
			height: 100%;

			.slick-track {
				height: 100%;

				.slick-slide {
					background: transparent no-repeat center center;
					background-size: cover;
				}

				.slide-video {
					position: relative;
					overflow: hidden;

					video {
						position: absolute;
						top: 50%; left: 50%;
						min-width: 100%;
						min-height: 100%;
						transform: translate(-50%, -50%);
					}
				}

				.slide-image {
					img {
						width: 100%;
						height: 100%;
						max-width: initial;
						max-height: initial;
						object-position: center;
						object-fit: cover;
					}
				}
			}
		}
	}
}


// Notifications

.notifications {
	background: $primary;
	text-align: center;

	.notification-content {
		padding: 1rem 0;
	}

	+ .page-header {
		margin-top: 0;
	}
}


// Main Content

main {
	&.cms-page {
		line-height: 1.5;
	}

	.row {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	h2 {
		font-family: $font-semi;
		position: relative;
		margin: 3rem 0 2rem;
		letter-spacing: 0.5px;
		line-height: 1;
		font-weight: 300;
		font-size: 225%;
	}

	h3 {
		position: relative;
		width: 100%;
		margin: 3rem 0 1rem;
		font-weight: 300;
		font-size: 187.5%;
	}

	h4 {
		position: relative;
		margin: 3rem 0 1rem;
		font-weight: 300;
		font-size: 150%;
	}

	h5 {
		position: relative;
		margin: 3rem 0 1rem;
		font-weight: 300;
		font-size: 130%;
	}

	h6 {
		position: relative;
		margin: 3rem 0 1rem;
		font-weight: 300;
		font-size: 110%;
	}

	h1, h2, h3, h4, h5, h6 {
		&:first-child {
			margin-top: 0;
		}

		&.accent {
			padding-left: 1.875rem;

			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 0; bottom: -5px; left: 0;
				background: $primary;
				width: 0.625rem;
			}
		}
	}

	p {
		margin: 0 0 1rem;
		font-weight: 300;
		font-size: 112.5%;
	}

	ul {
		margin: 0 0 1.5rem;
		padding-left: 1.5rem;
		list-style-type: disc;

		li {
			margin-bottom: 0.5rem;
			font-weight: 300;
		}

		ul {
			margin-bottom: 0;
		}

		&.checklist {
			padding-left: 0.5rem;

			li {
				display: block;
				position: relative;
				margin-bottom: 0.75rem;
				padding: 0 0 0 2rem;
				transition: all 0.25s ease;
				font-weight: 300;

				&::before {
					content: '';
					display: inline-block;
					position: absolute;
					top: 0; left: 0;
					background: $primary;
					width: 1.5rem;
					height: 1.5rem;
					margin-right: 0.75rem;
					border-radius: 1rem;
					border: 1px solid $primary;
					transition: all 0.25s ease;
					vertical-align: text-top;
				}

				&::after {
					content: '';
					position: absolute;
					top: 11px; left: 7px;
					background: $black;
					width: 2px;
					height: 2px;
					box-shadow: 2px 0 0 $black,
								4px 0 0 $black,
								4px -2px 0 $black,
								4px -4px 0 $black,
								4px -6px 0 $black,
								4px -8px 0 $black;
					transform: rotate(45deg);
				}
			}
		}
	}

	ol {
		margin: 0 0 1.5rem;
		padding-left: 1.5em;
		list-style: decimal;

		li {
			margin-bottom: 0.5em;
			font-weight: 300;
		}

		ol {
			margin-bottom: 0;
		}
	}

	b,
	strong {
		font-weight: 600;
	}

	u, s {
		text-decoration-thickness: 1px;
	}

	u {
		text-underline-offset: 2.5px;
	}

	a {
		position: relative;
		background-image: linear-gradient(90deg, rgba($primary, 0.5), rgba($primary, 0.5)), linear-gradient(90deg, rgba($primary, 0.25), rgba($primary, 0.25));
		background-size: 0 5px, 100% 5px;
		background-repeat: no-repeat;
		background-position: bottom left;
		transition: background-size 0.25s ease;
		color: $secondary;
		z-index: 1;

		&:hover {
			background-size: 100% 5px, 100% 5px;
		}

		&.fr-file {
			display: inline-block;
			background: none;
			padding: 0.75rem 1.375rem 0.75rem 3.375rem;
			border-radius: 10rem;
			border: 2px solid $black;
			transition: all 0.25s ease;
			letter-spacing: 0;
			overflow: hidden;
			font-size: 105%;

			&::before {
				content: '\f019';
				font-family: $font-awesome;
				display: flex;
				position: absolute;
				top: 50%; left: 1.25rem;
				background: none;
				width: 1.25rem;
				height: 1.25rem;
				padding: 0;
				transform: translate(0, -50%);
				justify-content: center;
				align-items: center;
				font-weight: 300;
				z-index: 0;
			}

			&::after {
				content: '';
				position: absolute;
				top: 0; bottom: 0; left: 0;
				background: $primary;
				width: 100%;
				height: auto;
				border-radius: 10rem;
				transform: translate(-100%, 0);
				transition: all 0.25s ease;
				z-index: -1;
			}

			&:hover {
				border-color: $primary;
				color: $white;

				&::after {
					transform: translate(0, 0);
				}
			}
		}
	}

	img {
		&.fr-dib {
			margin: 2rem auto;
		}

		&.fr-dii {
			margin-top: 0;

			&.fr-fil {
				margin-right: 1rem;
				margin-bottom: 2rem;
			}

			&.fr-fir {
				margin-bottom: 2rem;
				margin-left: 1rem;
			}
		}

		&.fr-bordered {
			padding: 3px;
			border: 3px solid $primary;
		}

		&.fr-shadow {
			box-shadow: 0px 10px 20px 0px rgba($black, 0.15);
		}
	}

	// Embed video responsively

	.fr-video {
		position: relative;
		max-width: 40rem;
		margin: 0;
		aspect-ratio: 16 / 9;

		&.fr-dvb {
			display: block;
			width: 100%;
			margin: 0 auto;
		}

		&.fr-fvl {
			margin: 0 auto 0 0;
		}

		&.fr-fvr {
			margin: 0 0 0 auto;
		}

		iframe,
		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100%;
		}
	}

	pre {
		overflow: scroll;
	}

	blockquote {
		background: rgba($black, 0.03);
		margin: 0 0 1rem;
		padding: 1.25rem;
		border-left: 0.75rem solid $primary;

		> :last-child {
			margin-bottom: 0;
		}
	}

	hr {
		background: $primary;
		height: 2px;
		margin: 1rem 0;
		border: 0 none;
	}

	table {
		&.fr-alternate-rows {
			tr {
				&:nth-child(odd) {
					background: $light-grey;
				}
			}
		}

		tr {
			th {
				background: $dark-grey;
				padding: 0.5rem 1rem;
				text-align: left;
				font-weight: 300;
				font-size: 112.5%;
				color: $white;
			}

			td {
				padding: 0.5rem 1rem;
				font-weight: 300;
				font-size: 112.5%;

				&.fr-highlighted {
					background: $primary;
					color: $white;
				}

				.fr-file {
					display: block;
					width: 2.5rem;
					height: 2.5rem;
					padding: 0;
					border-radius: 3px;
					border: 0 none;
					text-indent: -999rem;

					&::before {
						left: 50%;
						text-indent: initial;
						transform: translate(-50%, -50%);
					}

					&::after {
						content: none;
					}

					&:hover {
						background: $primary;
					}
				}
			}
		}
	}
}

#page-intro {
	max-width: 60rem;
	margin-right: auto;
	margin-left: auto;
	padding: clamp(2rem, 0.286rem + 7.619vw, 3rem) clamp(0.625rem, -1.518rem + 9.524vw, 1.875rem);
	text-align: center;

	> :last-child {
		margin-bottom: 0;
	}
}

.content-block-container {
	background: $light-grey;

	.expandable-title {
		background: darken($light-grey, 3%);
	}

	table {
		&.fr-alternate-rows {
			tr {
				&:nth-child(odd) {
					background: darken($light-grey, 3%);
				}
			}
		}
	}
}

.content-block {
	max-width: 80rem;
	margin: 0 auto;
	padding: clamp(2rem, 0.286rem + 7.619vw, 3rem) clamp(0.625rem, -1.518rem + 9.524vw, 1.875rem);

	section {
		+ section {
			margin-top: 4rem;
		}
	}

	.text {
		> :last-child {
			margin-bottom: 0;
		}
	}

	> .image {
		display: flex;
		padding: 0.75rem 0 2.25rem;
		justify-content: center;
		align-items: center;

		.flourish {
			position: relative;
			width: 88.25%;
			max-width: 28.125rem;
			margin-left: -0.5rem;

			&::before {
				content: '';
				position: absolute;
				top: -6%; left: -5%;
				background: $primary;
				width: 40%;
				height: 88%;
				z-index: 0;
			}

			&::after {
				content: '';
				position: absolute;
				bottom: -18%; right: -8%;
				background: $primary;
				width: 75%;
				height: 36%;
				z-index: 0;
			}
		}

		img {
			position: relative;
			z-index: 1;
		}
	}

	a {
		img {
			transition: all 0.25s ease;
		}
		&:hover {
			img {
				transform: scale(1.05);
			}
		}
	}
}


// Accordion

.accordion {
	display: flex;
	flex-wrap: wrap;

	.description {
		width: 100%;
		margin-bottom: 2rem;
	}
}

.expandable {
	width: 100%;
}

.expandable-title {
	display: block;
	position: relative;
	background: $light-grey;
	margin-bottom: 0.125rem;
	padding: 0.625rem 3rem 0.625rem 1rem;
	transition: all 0.25s ease;
	font-weight: 300;
	font-size: 112.5%;
	cursor: pointer;

	&::after {
		content: '\f078';
		font-family: $font-awesome;
		position: absolute;
		top: 50%; right: 1.25rem;
		transition: all 0.25s ease;
		transform: translateY(-50%);
		text-align: center;
		font-weight: 400;
		font-size: 87.5%;
	}

	&.active {
		background: $primary;

		&::after {
			transform: translateY(-50%) rotate(180deg);
		}
	}

	&:hover {
		background: $primary;
		color: $white;
	}
}

.expandable-content {
	display: none;
	padding: 1rem 1rem;

	> :last-child {
		margin-bottom: 0;
	}
}


// Gallery

.gallery {
	.description {
		width: 100%;
		margin-bottom: 2rem;
	}

	figure {
		border: 1px solid transparent;
		overflow: hidden;
		cursor: pointer;

		&:hover {
			img {
				transform: scale(1.1);
			}
		}

		img {
			transition: all 0.25s ease;
		}

		figcaption {
			display: none;
		}
	}
}


// Tiles

.tiles {
	&.masonry {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.tiles-row {
			display: flex;
			width: calc((100% - 1.875rem) / 2);
			width: 100%;
			justify-content: space-between;
			flex-wrap: wrap;

			.tile {
				display: flex;
				position: relative;
				background: transparent no-repeat center center;
				background-size: cover;
				width: 100%;
				margin: 0 0 0.625rem;
				padding: 1.25rem;
				justify-content: center;
				align-content: center;
				text-align: center;
				flex-wrap: wrap;
				color: $white;

				&::before {
					content: '';
					position: absolute;
					top: 0; right: 0; bottom: 0; left: 0;
					background: rgba($black, 0.5);
				}

				h3 {
					font-family: $font-semi;
					width: 100%;
					margin: 0 0 0.875rem;
					text-transform: uppercase;
					line-height: 1;
					font-weight: 500;
					font-size: 225%;
				}

				.description {
					display: none;
					position: relative;
					width: 100%;
					margin: 0.5rem 0 1rem;
					padding: 0 0.5rem;

					&:empty {
						display: none;
					}

					p {
						line-height: 1.2;
						font-weight: 100;
						font-size: 150%;
					}

					*:last-child {
						margin-bottom: 0;
					}
				}

				.button {
					margin-top: 0.875rem;
					color: $white;

					&:hover {
						color: $black;
					}
				}
			}
		}
	}

	&.hero {
		display: flex;
		flex-wrap: wrap;

		.tile {
			display: flex;
			background: transparent no-repeat center center / cover;
			width: 100%;
			min-height: 20rem;
			height: calc((100vh - 5rem) / 2);
			padding: 1.25rem;
			justify-content: center;
			align-items: center;
			text-align: center;
			overflow: hidden;

			&::before {
				content: '';
				position: absolute;
				inset: 0;
				background: inherit;
				transition: all 0.25s ease;
				z-index: 1;
			}

			&::after {
				content: '';
				position: absolute;
				inset: 0;
				z-index: 1;
			}

			&:nth-child(odd) {
				background-color: $primary;

				&::after {
					background: rgba($primary, 0.5);
				}
			}

			&:nth-child(even) {
				background-color: $secondary;

				&::after {
					background: rgba($secondary, 0.5);
				}
			}

			&:hover {
				&::before {
					transform: scale(1.1);
				}
			}

			> * {
				z-index: 2;
			}

			h2 {
				margin: 0;
				font-weight: 500;
				font-size: clamp(3rem, 0.517rem + 11.034vw, 5rem);
				color: $white;
			}
		}
	}

	&.icon-grid {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 2rem;

		.tile {
			width: 100%;

			.tile-img {
				display: block;
				width: 3.75rem;
				margin-bottom: 1rem;
				aspect-ratio: 1;
			}

			h3 {
				margin: 0 0 1rem;
				font-weight: 600;
				font-size: 1.25rem;
			}
		}
	}
}

.featured-tiles {
	width: 100%;
	max-width: $max-width;
	margin: 0 auto;
	padding: 1.5rem 1.25rem;

	.tile {
		display: flex;
		position: relative;
		background: transparent no-repeat center center;
		background-size: cover;
		margin: 1.5rem 0 0;
		padding: 1.875rem 1.25rem 2.125rem;
		justify-content: center;
		align-items: center;
		text-align: center;
		flex-wrap: wrap;
		color: $white;

		&:first-child {
			margin-top: 0;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			background: rgba($black, 0.5);
			z-index: 0;
		}

		h3 {
			font-family: $font-semi;
			width: 100%;
			margin: 0;
			text-transform: uppercase;
			font-weight: 500;
			font-size: 212.5%;
			z-index: 1;
			color: $white;
		}

		p {
			display: none;
			width: 100%;
			margin: 1rem 0 0;
			font-weight: 200;
			font-size: 150%;
			z-index: 1;
		}

		.button {
			width: 100%;
			max-width: 13rem;
			margin: 1.5rem 0.75rem 0;
			font-size: 0.875rem;
			color: $white;
			z-index: 1;

			&:hover {
				color: $black;
			}
		}
	}

	.novated-leasing {
		background-image: url('/img/placeholder-novated-leasing.jpeg');
	}

	.salary-packaging {
		background-image: url('/img/placeholder-salary-packaging.jpeg');
	}

	.exclusive-deals {
		background-image: url('/img/placeholder-exclusive-deals.jpeg');
	}
}


// Modal

.modal {
	display: flex;
	position: fixed;
	top: 50%; right: 1.25rem; left: 1.25rem;
	background: $white;
	max-width: 45rem;
	max-height: calc(100% - 2.5rem);
	margin: 0 auto;
	padding: 2rem;
	border-radius: 0.5rem;
	box-shadow: 0 2px 5px rgba($black, 0.15);
	transform: translateY(-50%);
	transition: all 0.25s ease;
	visibility: hidden;
	opacity: 0;
	z-index: 4;

	flex-direction: column;

	&.active {
		visibility: visible;
		opacity: 1;
	}

	.content {
		overflow-y: scroll;
	}

	.actions {
		margin-top: 1.5rem;
	}

	.close {
		position: absolute;
		top: 0; right: 0;
		background: transparent;
		width: 1rem;
		height: auto;
		padding: 0.75rem;
		border: 0 none;
		border-radius: 0 0 0 0.5rem;
		font-size: 125%;
		color: $black;

		&::before {
			content: none;
		}

		&:hover {
			background: $primary;
			color: $white;
		}

		i {
			display: block;
			width: 1.25rem;
			height: 1.25rem;
		}
	}

	&.form {
		overflow: scroll;
		z-index: 5;

		&.slim {
			width: calc(100% - 2.5rem);
			max-width: 26.25rem;
		}

		p {
			font-size: 100%;

			&.preamble {
				font-family: $font-semi;
				margin: 0;
				text-transform: uppercase;
				font-weight: 500;
			}
		}

		.actions {
			flex-direction: column;
		}

		button {
			width: auto;
		}

		.cancel {
			background: $light-grey;
			font-size: 75%;
			color: $black;

			&::before {
				background: $dark-grey;
			}

			&:hover {
				color: $white;
			}
		}
	}
}

.note {
	font-style: italic;
	font-size: 87.5%;
}


// Error Pages

.text-error {
	position: absolute;
	top: 50%; left: 50%;
	width: 100%;
	padding: 0.5rem;
	transform: translate(-50%, -50%);
	text-align: center;
}


// Responsive Table

.responsive-table {
	width: 100%;
	border-collapse: collapse;

	thead {
		display: none;
	}

	tr {
		display: block;
		padding: 1rem 0;

		+ tr {
			border-top: 1px solid $black;
		}
	}

	td {
		display: block;
	}

	td {
		&::before {
			content: attr(data-label);
			display: block;
			text-transform: uppercase;
			font-weight: 600;
			font-size: 75%;
		}

		div {
			display: inline-block;
			margin-left: 1rem;
			vertical-align: top;
		}
	}

	@media screen and (min-width: 769px) {
		thead {
			display: table-header-group;
		}

		tr {
			display: table-row;
			padding: 0;
		}

		td {
			display: table-cell;

			&::before {
				display: none;
			}

			div {
				margin-left: 0;
			}
		}
	}
}


// Pagination

.simple-pagination {
	display: flex;
	margin: 0 auto;
	padding: 1.875rem;
	justify-content: center;
	align-items: center;
	list-style: none;

	a {
		display: flex;
		background: none;
		text-transform: uppercase;
		align-items: center;
		font-weight: 400;
		font-size: 87.5%;

		&.prev {
			&:hover {
				i {
					margin-right: 0.25rem;
					margin-left: 0.375rem;
				}
			}

			i {
				margin-right: 0.625rem;
				transition: all 0.25s ease;
			}
		}

		&.next {
			&:hover {
				i {
					margin-right: 0.375rem;
					margin-left: 0.25rem;
				}
			}

			i {
				margin-left: 0.625rem;
				transition: all 0.25s ease;
			}
		}
	}
}

.pagination {
	display: flex;
	margin: 0 auto;
	padding: 1.875rem;
	justify-content: center;
	align-items: center;
	list-style: none;

	li {
		margin: 0;
	}

	a,
	span {
		display: flex;
		background: none;
		min-height: 2.5rem;
		min-width: 2.5rem;
		padding: 0.5rem;
		border-radius: 2.5rem;
		transition: all 0.25s ease;
		text-transform: uppercase;
		justify-content: center;
		align-items: center;
		font-weight: 400;
		line-height: 1;

		i {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&.prev {
			i {
				margin-right: 0.125rem;
				transition: all 0.25s ease;
			}
		}

		&.next {
			i {
				margin-left: 0.125rem;
				transition: all 0.25s ease;
			}
		}
	}

	a {
		&:hover {
			background: $primary;
		}
	}
}


// Animations

@keyframes slideIn {
	from {
		filter: blur(15px);
	}

	to {
		filter: blur(0);
	}
}

@keyframes slideOut {
	from {
		filter: blur(0);
	}

	to {
		filter: blur(15px);
	}
}

@keyframes fadeInDown {
	0% {
		transform: translate(-50%, -2rem);
		opacity: 0;
	}

	50%,
	100% {
		transform: translate(-50%, 0);
		opacity: 1;
	}
}


// Page Specific

// -- Home

#home {
	.hero {
		position: relative;

		.prev,
		.next {
			position: absolute;
			bottom: 3rem; left: 50%;
			background: transparent;
			width: 2.5rem;
			height: 2.5rem;
			padding: 0;
			border-radius: 10rem;
			border: 1px solid $primary;
			color: $primary;

			&::before {
				content: '';
				position: absolute;
				top: 50%; left: 50%;
				background: $primary;
				width: 0;
				height: 0;
				border-radius: 10rem;
				transform: translate(-50%, -50%);
				transition: all 0.5s ease;
				opacity: 0;
				z-index: -1;
			}

			&:hover {
				border-color: $primary;
				color: $dark-grey;

				&::before {
					width: 100%;
					height: 100%;
					opacity: 1;
				}
			}
		}

		.prev {
			transform: translate(calc(-50% - 1.75rem), 0);
			padding-right: 1px;
		}

		.next {
			transform: translate(calc(-50% + 1.75rem), 0);
			padding-left: 1px;
		}

		.slide {
			position: relative;
			width: 100vw;
			height: calc(100vh - 5rem);

			&::before {
				content: '';
				position: absolute;
				top: 0; right: 0; bottom: 0; left: 0;
				background: rgba($black, 0.5);
				z-index: 0;
			}

			&.image {
				background: transparent no-repeat center center;
				background-size: cover;
			}

			.caption-container {
				position: absolute;
				top: 3rem; right: auto; bottom: 8.5rem; left: 50%;
				width: calc(100% - 3rem);
				max-width: $max-width;
				transform: translate(-50%, 0);
			}

			.caption {
				position: absolute;
				bottom: 0; left: 50%;
				width: 100%;
				max-width: 30rem;
				transform: translate(-50%, 0);

				h1 {
					margin: 1rem 0 1.5rem;
					font-family: $font-semi;
					line-height: 1;
					font-size: 375%;
				}

				.button {
					color: $white;

					&:hover {
						color: $black;
					}
				}
			}
		}
	}

	#hero {
		max-width: 100%;
		padding: 0;
	}

	.featured-tiles-container {
		background: $light-grey;
	}

	#car-of-the-month {
		position: relative;
		background: transparent url('/img/background-car-of-the-month.jpeg') no-repeat center center;
		background-size: cover;
		padding: 5rem 1.25rem;
		box-shadow: inset 0 5px 25px 0 rgba($black, 0.25),
					inset 0 -5px 25px 0 rgba($black, 0.25);

		.panel {
			position: relative;
			background: rgba($white, 0.9);
			max-width: 63.75rem;
			margin: 0 auto;
			padding: 2rem 1.25rem;
			box-shadow: 25px 30px 100px 0 rgba($black, 0.15),
						3px 5px 25px 0 rgba($black, 0.15);
			text-align: center;

			img {
				max-width: 15rem;
				margin: 0 auto;
			}

			h3 {
				margin-bottom: 2rem;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-weight: 500;
				font-size: 87.5%;

				&::after {
					content: '';
					position: absolute;
					top: calc(100% + 1rem); left: 50%;
					background: $primary;
					height: 3px;
					width: 6.25rem;
					transform: translate(-50%, 0);
				}
			}

			.savings {
				font-family: $font-semi;
				margin-bottom: 0.75rem;
				text-transform: uppercase;
				font-weight: 700;
				font-size: 300%;
				color: $highlight;

				.asterisk {
					vertical-align: super;
					font-size: 50%;
				}
			}

			.blurb {
				max-width: 25rem;
				margin: 0 auto 1.75rem;
				font-size: 125%;

				.car-name {
					font-weight: 500;
				}
			}

			.button {
				border-color: $black;
				font-size: 87.5%;
				color: $black;
			}
		}
	}

	#social-feeds {
		display: flex;
		background: $light-grey;
		padding: 3rem 1.25rem;
		justify-content: center;
		flex-wrap: wrap;

		#blog {
			background: $white;
			width: 100%;
			margin: 0 0 3rem;
			box-shadow: 0 0 10px 0 rgba($black, 0.05);
			overflow: hidden;

			.featured-image {
				display: block;
				background: transparent no-repeat center center;
				background-size: cover;
				width: 100%;
				height: 11rem;
			}

			.content {
				margin-top: 0;
				padding: 1.25rem 1rem;

				.title {
					font-family: $font-semi;
					margin-bottom: 0.625rem;
					font-weight: 400;
					font-size: 150%;
				}

				.extract {
					font-family: $font-semi;
					margin-bottom: 0.625rem;
				}

				.more {
					display: table;
					background: none;
					margin: 0 0 0 auto;
					text-transform: uppercase;
					transition: all 0.25s ease;
					font-weight: 600;
					font-size: 87.5%;

					&:hover {
						margin-right: 0.375rem;

						i {
							margin-left: 0.25rem;
						}
					}

					i {
						margin-left: 0.625rem;
						transition: all 0.25s ease;
					}
				}
			}
		}

		#instagram {
			width: 100%;

			#instafeed {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
			}

			.insta-tag {
				display: block;
				position: relative;
				background: transparent no-repeat center center;
				background-size: cover;
				width: calc((100% - 0.625rem) / 2);
				margin-bottom: 0.625rem;
				padding-top: calc((100% - 0.625rem) / 2);

				&::before,
				&::after {
					content: none;
				}

				&:hover {
					.rollover {
						visibility: visible;
						opacity: 1;
					}
				}

				.rollover {
					display: flex;
					position: absolute;
					top: 0; right: 0; bottom: 0; left: 0;
					background: rgba($black, 0.5);
					transition: all 0.25s ease;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					flex-wrap: wrap;
					text-align: center;
					visibility: hidden;
					opacity: 0;
					color: $white;

					i {
						font-size: 28px;
					}
				}
			}
		}
	}

	#testimonials {
		position: relative;
		padding: 3rem 1.25rem 6.75rem;

		.testimonials {
			max-width: 36rem;
			margin: 0 auto;
		}

		.testimonial {
			img {
				width: 100%;
				max-width: 7.5rem;
				margin: 0 auto 1.25rem;
				border-radius: 10rem;
				box-shadow: 2px 2px 10px 0 rgba($black, 0.15);
			}

			.content {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				.quote {
					width: 100%;
					margin: 0 0 0.75rem;
					text-align: center;

					> *:last-child {
						margin-bottom: 0;
					}
				}

				.rating {
					display: flex;
					margin: 0 auto 0 0;
					align-items: center;

					i {
						color: $primary;
					}
				}

				.name {
					margin: 0 0 0 auto;
					font-style: italic;
				}
			}
		}

		.prev,
		.next {
			position: absolute;
			bottom: 3rem; left: 50%;
			background: transparent;
			width: 2.5rem;
			height: 2.5rem;
			padding: 0;
			border-radius: 10rem;
			border: 1px solid $primary;

			&::before {
				content: '';
				position: absolute;
				top: 50%; left: 50%;
				background: $primary;
				width: 0;
				height: 0;
				border-radius: 10rem;
				transform: translate(-50%, -50%);
				transition: all 0.5s ease;
				opacity: 0;
				z-index: -1;
			}

			&:hover {
				border-color: $primary;
				color: $dark-grey;

				&::before {
					width: 100%;
					height: 100%;
					opacity: 1;
				}
			}
		}

		.prev {
			transform: translate(calc(-50% - 1.75rem), 0);
			padding-right: 1px;
		}

		.next {
			transform: translate(calc(-50% + 1.75rem), 0);
			padding-left: 1px;
		}
	}
}


// -- Contact Forms

.contact-form {
	display: flex;
	max-width: 80rem;
	margin: 0 auto;
	padding: clamp(2rem, 0.286rem + 7.619vw, 3rem) clamp(0.625rem, -1.518rem + 9.524vw, 1.875rem);
	flex-wrap: wrap;
	gap: 1.875rem;

	#page-intro {
		margin: 0;
		padding: 0;
		text-align: left;
	}
}

#contact-form,
#dealers-enquiry-form,
#employer-employee-enquiry-form {
	width: 100%;
	max-width: 25rem;
	margin: 0 auto;
}

#contact-form {
	#message {
		min-height: 8rem;
	}
}


// -- Novated Leasing Calculator

.leasing-calculator {
	max-width: 60rem;
	margin: 0 auto;
	padding: 2rem 0;

	.toggle {
		margin-bottom: 2rem;
	}

	.calculator {
		width: 100%;
		max-width: 22rem;
		margin: 0 auto 2rem;
		padding: 2rem;

		.calculator-options {
			position: relative;
			min-height: 4.25rem;
			margin-bottom: 1.5rem;
		}

		.pick-a-car,
		.fixed-price {
			visibility: hidden;
			opacity: 0;

			&.active {
				visibility: visible;
				opacity: 1;
			}
		}

		.fixed-price {
			position: absolute;
			top: 0;
			transition: all ease 0.25s;
		}

		.price-error {
			display: none;
			border-radius: 3px;
			color: $white;
		}

		.actions {
			button {
				&:hover {
					color: $white;
				}
			}
		}
	}

	.selected-car {
		display: none;
		margin: 0 auto 2rem;
		padding: 0 2rem;
		color: $primary;

		.car-placeholder {
			display: block;
			width: calc(100% - 2rem);
			max-width: 28rem;
			margin: 0 auto;
			fill: currentColor;
		}
	}
}

#calculator-results {
	p {
		text-align: center;

		&.savings-value {
			font-family: $font-semi;
			margin-bottom: 0.25rem;
			font-weight: 500;
			font-size: 262.5%;
			color: $positive-text;
		}

		&.clause {
			margin-bottom: 1.25rem;
			font-weight: 500;
			font-size: 93.75%;
		}

		&.description {
			margin-bottom: 1.25rem;
		}
	}
}

.toggle {
	display: flex;
	margin: 0 auto;
	border-radius: 10rem;
	justify-content: center;

	button {
		font-family: $font-semi;
		background: darken($white, 6.5%);
		padding: 0.25rem 1rem;
		border: 0 none;
		letter-spacing: 0.7px;
		line-height: 1.375rem;
		font-weight: 300;
		color: $secondary;

		&::before {
			content: none;
		}

		&::after {
			content: '';
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			background: $primary;
			width: 100%;
			height: 100%;
			border-radius: 1rem;
			border: 0 none;
			box-shadow: 2px 0 5px 0 rgba($black, 0.1),
						-2px 0 5px 0 rgba($black, 0.1);
			transform: translate(-100%, 0);
			transition: all ease 0.25s;
			z-index: -1;
		}

		&:first-child {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;

			&::after {
				transform: translate(100%, 0);
			}
		}

		&:last-child {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		&.active {
			color: $white;

			&::after {
				transform: translate(0, 0);
			}
		}
	}
}

.rangeslider {
	display: flex;
	position: relative;
	width: 100%;
	margin: 0 auto 2rem;
	flex-wrap: wrap;

	label {
		font-family: $font-semi;
		width: 50%;
		text-transform: uppercase;
		color: $black;
	}

	.output-value {
		text-align: right;

		&.bubble {
			position: absolute;
			top: 1.5rem; left: 0;
			background: $white;
			width: auto;
			padding: 0.5rem 1rem;
			border-radius: 3px;
			box-shadow: 5px 5px 20px rgba($black, 0.15);
			transform: translate(-50%, -50%);
			white-space: nowrap;

			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 100%; left: 50%;
				width: 0;
				margin: 0 auto;
				border: 0.5rem solid transparent;
				border-top-color: $white;
				transform: translate(-50%, 0);
			}

			+ .range {
				margin-top: 4.5rem;
			}
		}
	}

	.range {
		background: darken($white, 10%);
		width: 100%;
		height: 5px;
		margin: 1rem 0;
		border-radius: 0.5rem;
		-webkit-appearance: none;
		appearance: none;

		// Combining these prevents them from loading..
		&::-webkit-slider-thumb {
			appearance: none;
			background: $primary;
			width: 1.125rem;
			height: 1.125rem;
			border-radius: 50%;
			cursor: ew-resize;
		}

		&::-moz-range-thumb {
			appearance: none;
			background: $primary;
			width: 1.125rem;
			height: 1.125rem;
			border-radius: 50%;
			border: none;
			cursor: ew-resize;
		}

		&::-ms-thumb {
			appearance: none;
			background: $primary;
			width: 1.125rem;
			height: 1.125rem;
			border-radius: 50%;
			cursor: ew-resize;
		}
	}
}


// -- Salary Packaging Calculator

#packaging-calculator {
	max-width: 80rem;
	margin: 0 auto;
	padding: clamp(2rem, 0.286rem + 7.619vw, 3rem) clamp(0.625rem, -1.518rem + 9.524vw, 1.875rem);

	.panel {
		margin-bottom: 3.125rem;

		> .option {
			h4 {
				margin-bottom: 2rem;
				border-bottom: 2px solid $primary;
			}

			h5 {
				margin-top: 0;
				margin-bottom: 0.5rem;
				font-weight: 500;
			}

			.subs-container {
				padding: 0 0.5rem;
			}

			.uploaded {
				background: $light-grey;
				margin-bottom: 3.125rem;
				padding: 0.75rem 1.25rem;

				.actions {
					margin-bottom: 0;
				}
			}

			.subs-form {
				.actions {
					button,
					.button {
						font-size: 87.5%;
					}
				}
			}
		}
	}

	.rangeslider {
		max-width: calc(100% - 4.25rem);
	}

	.industries {
		[type="radio"] {
			&:checked {
				+ label {
					background: $primary;
					color: $black;
				}
			}
		}

		label {
			background: $light-grey;
			margin: 0.125rem 0;
			padding: 0.5rem 1rem;
			transition: all 0.25s ease;
			line-height: 1.5;
			font-weight: 300;
			font-size: 112.5%;
			color: $black;

			&::before,
			&::after {
				content: none;
			}

			&:hover {
				background: $dark-grey;
				color: $white;
			}
		}
	}

	#your-benefits {
		.instruction {
			margin-bottom: 3.125rem;
		}
	}

	.benefit-category {
		margin-bottom: 3.125rem;
	}

	.benefits {
		.package-amount {
			position: relative;
			max-width: 12.5rem;
			margin-left: auto;

			&::before {
				content: '$';
				position: absolute;
				top: 0.5rem;
				left: 0.625rem;
				font-weight: 400;
				font-size: 112.5%;
			}

			label {
				@include sr-only;
			}

			input {
				padding-left: 2rem;
				text-align: right;

				// Turn off arrow spinners in number input
				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					margin: 0;
					-webkit-appearance: none;
				}

				&[type=number] {
					appearance: textfield;
				}
			}
		}
	}

	.actions {
		margin-bottom: 3.125rem;

		button {
			width: auto;
			padding: 0.5rem 2.5rem;
			font-size: 125%;
		}
	}

	#error-reason {
		font-size: 100%;
	}
}

.summary-table {
	width: 100%;
	border-spacing: 0;

	th {
		background: transparent;
		font-size: 100%;
		color: $black;
	}

	td {
		text-align: right;
		font-size: 100%;

		&.currency {
			white-space: nowrap;

			&::before {
				content: '$';
				float: left;
				margin-right: 0.5rem;
				font-weight: 500;
			}
		}
	}

	tr {
		> :first-child {
			width: 7.5rem;
		}

		> :last-child {
			background: $primary;
			border-top-color: rgba($white, 0.5);
		}

		> :nth-child(2) {
			font-size: 87.5%;
		}
	}

	thead {
		th {
			text-align: center;
		}
	}

	tbody {
		tr {
			> * {
				border-top: 1px solid darken($light-grey, 3.5%);
			}
		}
	}

	tfoot {
		tr {
			> * {
				border-top: 3px double darken($light-grey, 3.5%);
				font-weight: 500;
			}
		}
	}
}

#calculator-confirmed {
	padding: 5rem 0;
}


// -- Auth Forms

form.auth {
	width: 100%;
	max-width: 30rem;
	margin: 0 auto;
	padding: 5rem 1.25rem;

	.actions {
		margin-top: 2rem;
	}
}


// -- Blog

.tag-results {
	max-width: 80rem;
	margin: 2rem auto 2.5rem;
	padding: 0 1rem;
	text-align: center;
	line-height: 1.5;
	font-size: 120%;
}

.post-container {
	.post {
		.image {
			display: block;
			background: $primary url('/img/placeholder-page-header.jpg') no-repeat center center;
			background-size: cover;
			width: 100%;
			height: 22.5rem;
		}

		.body {
			padding: 1.875rem 1.375rem 1.875rem 0;

			.date {
				margin: 0 0 1.25rem 1.875rem;
				font-size: 87.5%;
				color: lighten($black, 40%);
			}

			.title {
				font-family: $font-semi;
				margin: 0 0 1.25rem;
				line-height: 1;
				font-size: 175%;

				a {
					background: none;
				}
			}

			.extract {
				margin: 0 0 1.375rem 1.875rem;
				line-height: 1.7;

				p {
					font-size: 100%;
				}

				*:last-child {
					margin-bottom: 0;
				}
			}

			.more {
				background: none;
				margin: 0 0 0 1.875rem;
				text-transform: uppercase;
				font-weight: 600;
				font-size: 87.5%;

				&:hover {
					i {
						margin-left: 0.25rem;
					}
				}

				i {
					margin-left: 0.625rem;
					transition: all 0.25s ease;
				}
			}
		}
	}
}

.blog-post {
	.page-header {
		h1 {
			max-width: 37.5rem;
		}
	}

	.content {
		max-width: 40.625rem;
		margin: 0 auto;
		padding: 1.25rem 1.25rem 3rem;
		line-height: 1.7;

		p {
			font-size: 100%;
		}

		> *:last-child {
			margin-bottom: 0;
		}
	}

	.tags {
		display: flex;
		max-width: 40.625rem;
		margin: 0 auto;
		padding: 0 1.25rem 3rem;
		list-style: none;
		flex-wrap: wrap;

		li {
			display: flex;
			margin: 0;

			a {
				background: darken($light-grey, 5.5%);
				margin: 0.25rem 0.5rem 0.25rem 0;
				padding: 0.375rem 0.625rem;
				transition: all 0.25s ease;
				text-transform: uppercase;
				font-size: 75%;

				&:hover {
					background: $primary;
				}
			}
		}
	}
}

.related-posts {
	background: $light-grey;
	padding: 1.875rem 0.25rem;

	.centraliser {
		max-width: 1109px;
	}

	.post {
		display: flex;
		background: none;

		&:not(:first-child) {
			margin-top: 1.875rem;
			padding-top: 1.875rem;
			border-top: 1px solid darken($light-grey, 17%);
		}

		&:hover {
			.body {
				.more {
					i {
						margin-left: 0.5rem;
					}
				}
			}
		}

		.image {
			background: $primary url('/img/placeholder-page-header.jpg') no-repeat center center;
			background-size: cover;
			width: 31.25%;
			height: auto;
		}

		.body {
			width: 68.75%;
			padding: 0.5rem 0 0.5rem 1.25rem;

			.date {
				margin-bottom: 0.625rem;
				font-size: 75%;
				color: lighten($dark-grey, 20%);
			}

			.title {
				margin: 0 0 0.625rem;
				line-height: 1.3;
				font-size: 125%;
			}

			.extract {
				display: none;
			}

			.more {
				font-family: $font-semi;
				text-transform: uppercase;
				font-weight: 500;
				font-size: 75%;

				i {
					margin-left: 0.125rem;
					transition: all 0.25s ease;

					&::before {
						font-size: 87.5%;
					}
				}
			}
		}
	}
}


// -- Exclusive Deals

.deals {
	display: flex;
	max-width: 80rem;
	margin: 0 auto;
	padding: 0;
	justify-content: center;
	text-align: center;
	flex-wrap: wrap;

	.deal {
		width: 100%;
		padding: 3rem 1rem;

		&:nth-child(even) {
			background: $light-grey;
		}

		.model {
			margin: 0 0 1rem;
			font-weight: 600;
			font-size: 125%;
		}

		img {
			width: auto;
			max-height: 12.5rem;
			margin: 0 auto;
		}

		.rrp {
			margin: 0 0 1rem;
			font-weight: 400;
			font-size: 87.5%;
			color: $medium-grey;
		}

		.rem-price {
			margin: 0;
			font-weight: 600;
			font-size: 112.5%;
			color: $tertiary;

			b {
				font-weight: 600;
				font-size: 187.5%;
				color: $highlight;
			}
		}

		.fineprint {
			margin: 0;
			text-transform: uppercase;
			font-weight: 400;
			font-size: 68.75%;
			color: $medium-grey;
		}
	}
}

.ev-deals {
	.deal {
		.model {
			margin-bottom: 0;
		}

		.subtitle {
			margin-bottom: 0.375rem;
		}

		.type {
			display: inline-block;
			background: $positive;
			margin-bottom: 0.5rem;
			padding: 0.125rem 0.5rem;
			border-radius: 3px;
			font-weight: 500;
			font-size: 0.75rem;
			color: $white;
		}

		img {
			width: 100%;
			max-width: 12.5rem;
			max-height: 8rem;
			margin-bottom: 0.75rem;
			object-fit: cover;
		}

		.rem-price {
			margin-bottom: 0.375rem;
			text-transform: uppercase;
			font-size: 0.75rem;
			color: $highlight;

			.price-pretext {
				width: 100%;
				color: $tertiary;
			}

			.price-value {
				line-height: 1;
				font-size: 2.125rem;
			}
		}
	}
}

.disclaimer {
	background: $light-grey;
	margin: 0 1.25rem;
	padding: 0.75rem 1.25rem;
	font-size: 100%;
}

#exclusive-offer {
	position: relative;
	background: $primary no-repeat center center;
	background-size: cover;
	padding: 3rem 1.25rem;
	border-bottom: 5px solid $primary;

	&::before {
		content: '';
		position: absolute;
		inset: 0;
		background: rgba($black, 0.6);
	}

	.inner {
		position: relative;
		max-width: 38rem;
		margin: 0 auto;

		h3 {
			font-family: $font-semi;
			margin-bottom: 2.75rem;
			text-transform: uppercase;
			text-align: center;
			line-height: 120%;
			font-weight: 600;
			font-size: 225%;
			color: $white;
		}

		img {
			width: 100%;
			max-width: 28.75rem;
			margin: 0 auto -3.5rem;
		}

		.offer {
			background: $primary;
			margin: 0 0 0.75rem;
			padding: 4rem 1.25rem 1.5rem;
			text-align: center;
			line-height: 1.3;
			font-weight: 600;
			font-size: 150%;
		}
	}

	.valid {
		position: relative;
		margin: 0 auto;
		text-align: center;
		font-size: 75%;
		opacity: 0.8;
		color: $white;
	}
}

#exclusive-deals-contact-form,
#exclusive-ev-deals-contact-form {
	p {
		text-align: center;

		&.car-name {
			font-family: $font-semi;
			margin-bottom: 1.5rem;
			font-weight: 500;
			font-size: 262.5%;
			color: $positive-text;
		}
	}
}


// -- Rem Lite

.headless {
	@include clearfix;

	header {
		nav,
		.icon {
			display: none;
		}
	}

	#logo {
		margin: 0 auto;
	}

	footer {
		nav.footer,
		.privacy,
		.separator,
		.terms {
			display: none;
		}
	}
}

#rem-lite {
	#page-intro {
		text-align: left;
	}
}

#rem-lite-login-form {
	width: 100%;
	max-width: 30rem;
	margin: 0 auto;
	padding: 5rem 1rem;

	.actions {
		margin-top: 2rem;
	}
}

.rem-lite-form {
	max-width: 60rem;
}

.optional,
.postal-address {
	width: 100%;
}

.optional {
	display: none;
}

.upload-header {
	display: flex;
	align-items: center;

	label {
		width: auto;
		margin-left: auto;
		font-size: 46.66%;
	}
}

.fileuploader {
	display: none;
	background: transparent;
	margin: 0;
	padding: 0;
	border-radius: 0;
}

.uploads {
	width: 100%;
	box-shadow: 0 3px 10px rgba($black, 0.15);

	.upload {
		display: flex;
		margin: 0;
		padding: 0.5rem 0.5rem 0.5rem 1rem;
		justify-content: space-between;
		align-items: center;

		+ .upload {
			border-top: 1px solid darken($light-grey, 8%);
		}

		.file {
			background: none;
		}

		.actions {
			button,
			.button {
				background: $light-grey;
				width: 2rem;
				margin: 0;
				padding: 0.25rem;
				border: 0 none;
				border-radius: 3px;
				text-align: center;
				font-size: 87.5%;

				&::before {
					content: none;
				}

				+ button,
				+ .button {
					margin-left: 0.5rem;
				}
			}
		}

		.view {
			&:hover {
				background: $primary;
			}
		}

		.delete {
			color: $negative;

			&:hover {
				background: $negative;
				color: $white;
			}
		}
	}
}

.create-application {
	display: block;
	width: 100%;
	margin-bottom: 1rem;
	text-align: center;
}

#dealer-applications {
	width: 100%;
	border-top: 1px solid $black;

	tr {
		position: relative;
	}

	.button {
		background: $light-grey;
		width: 2rem;
		padding: 0.25rem;
		border: 0 none;
		border-radius: 3px;
		text-align: center;
		vertical-align: middle;

		&::before {
			content: none;
		}

		&:hover {
			background: $primary;
		}
	}
}

.header-actions {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	h1, h2, h3, h4, h5, h6 {
		width: auto;
	}

	button,
	.button {
		margin-left: auto;
	}
}

.business_use {
	display: none;
	width: 100%;
}

.statuses {
	text-align: center;

	.status {
		background: $light-grey;
		margin: 2rem 0;
		padding: 0.5rem;
		border-radius: 10rem;
		border: 2px solid $medium-grey;
		font-size: 130%;
	}
}


// Responsive

@media only screen and (min-width: 401px) {
	footer {
		nav.footer {
			display: flex;

			ul {
				display: flex;
				margin: 0 auto;
			}
		}
	}

	.gallery {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		figure {
			width: calc(100% / 2);
		}
	}

	.create-application {
		display: inline-block;
	}
}

@media only screen and (min-width: 451px) {
	.tiles {
		&.masonry {
			.tiles-row {
				.tile {
					.description {
						display: block;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	#dealer-applications {
		tr {
			td {
				width: calc(100% - 2.5rem);
			}
		}

		.edit {
			position: absolute;
			top: 50%; right: 0.5rem;
			width: 2rem;
			height: 2rem;
			padding: 0;
			transform: translate(0, -50%);
			text-align: center;
		}
	}
}

@media only screen and (min-width: 451px) and (max-width: 768px) {
	#dealer-applications {
		tr {
			display: flex;
			flex-wrap: wrap;

			td {
				width: calc((100% - 2.5rem) / 2);
			}
		}
	}
}

@media only screen and (min-width: 501px) and (max-width: 768px) {
	#packaging-calculator {
		.industries {
			display: flex;
			flex-wrap: wrap;

			label {
				display: flex;
				width: calc((100% - 0.75rem) / 3);
				min-height: 4.625rem;
				margin-right: 0.125rem;
				margin-left: 0.125rem;
				justify-content: center;
				align-items: center;
				text-align: center;
			}
		}
	}
}

@media only screen and (min-width: 501px) {
	.create-application {
		width: auto;
	}

	#packaging-calculator {

		#apply-online {
			#email {
				max-width: 30rem;
			}
		}
	}

	.summary-table {
		tr {
			> :first-child {
				width: auto;
			}
		}
	}

	.tiles {
		&.icon-grid {
			.tile {
				width: calc((100% - 2rem) / 2);
			}
		}
	}
}

@media only screen and (min-width: 569px) {
	.page-header {
		.more {
			display: none;
		}
	}

	#page-intro {
		padding: 3rem 1.875rem;
	}

	.content-block {
		padding: 3rem 1.875rem;
	}

	.gallery {
		figure {
			width: calc(100% / 3);
		}
	}

	.tiles {
		&.masonry {
			.tiles-row {
				&:nth-child(2n+1) {
					.tile {
						&:nth-child(3n+2),
						&:nth-child(3n+3) {
							width: calc((100% - 1.875rem) / 2);

							.description {
								display: none;
							}
						}
					}
				}

				&:nth-child(2n+2) {
					.tile {
						&:nth-child(3n+1),
						&:nth-child(3n+2) {
							width: calc((100% - 1.875rem) / 2);

							.description {
								display: none;
							}
						}
					}
				}

				.tile {
					margin-bottom: 1.875rem;
				}
			}
		}
	}

	.post-container {
		&:nth-of-type(even) {
			background: $light-grey;
		}

		&:nth-of-type(odd) {
			.post {
				.image {
					order: 2;
				}

				.body {
					order: 1;
				}
			}
		}

		.post {
			display: flex;

			.image {
				width: 40%;
				height: auto;
			}

			.body {
				width: 60%;
				padding-right: 1.75rem;
			}
		}
	}

	#home {
		.featured-tiles {
			padding: 2rem 1.75rem;

			.tile {
				margin: 2rem 0 0;
				padding: 4.25rem 1.25rem;

				h3 {
					font-size: 225%;
				}

				p {
					display: block;
				}
			}
		}

		#social-feeds {
			padding-right: 2rem;
			padding-left: 2rem;

			#blog {
				.featured-image {
					height: 15.5rem;
				}
			}
		}
	}

	.blog-post {
		.content {
			padding: 1.875rem 1.875rem 3rem;
		}

		.tags {
			padding-right: 1.875rem;
			padding-left: 1.875rem;
		}
	}

	.related-posts {
		padding: 3.125rem 0.875rem;

		.centraliser {
			display: flex;
			justify-content: space-between;
		}

		.post {
			width: calc((100% - 1.875rem) / 2);
			box-shadow: 0 0 10px rgba($black, 0.05);
			transition: all 0.25s ease;
			flex-wrap: wrap;

			&:not(:first-child) {
				margin-top: 0;
				padding-top: 0;
				border-top: 0 none;
			}

			&:nth-child(3) {
				display: none;
			}

			&:hover {
				box-shadow: 0 30px 50px -10px rgb($black, 0.15);
			}

			.image {
				width: 100%;
				padding-top: 63.72%;
			}

			.body {
				background: $white;
				width: 100%;
				padding: 1.25rem;

				.title {
					font-size: 150%;
				}
			}
		}
	}
}

@media only screen and (min-width: 650px) {
	.leasing-calculator {
		display: flex;
		align-items: center;

		.calculator {
			margin-bottom: 0;
		}

		.selected-car {
			display: block;
			width: calc(100% - 22rem);
			margin-bottom: 5rem;
			padding-left: 0.5rem;

			img {
				max-width: 30rem;
				margin: 0 auto;
			}
		}
	}

	.tiles {
		&.hero {
			.tile {
				width: 50%;
				height: calc(100vh - 5rem);

				h2 {
					font-size: clamp(3rem, 1.354rem + 4.051vw, 5rem);
				}
			}
		}
	}
}

// @media only screen and (min-width: 701px) and (max-width: 940px) {
// 	footer {
// 		.centraliser {
// 			display: block;
// 			padding: 3rem 3rem 2.5rem;
// 		}

// 		.phone {
// 			float: left;
// 			margin-bottom: 1.875rem;
// 			clear: left;
// 		}

// 		.social {
// 			float: left;
// 			width: 16rem;
// 			margin-bottom: 4.375rem;
// 			clear: left;
// 		}

// 		nav.footer {
// 			float: right;
// 			margin-bottom: 1.25rem;
// 			clear: right;

// 			ul {
// 				li {
// 					+ li {
// 						margin-left: 1.25rem;
// 					}

// 					a {
// 						padding: 0.5rem 0;
// 					}
// 				}
// 			}
// 		}

// 		.legal {
// 			float: right;
// 			margin-bottom: 4.375rem;
// 			text-align: right;
// 			clear: right;
// 		}

// 		.top {
// 			position: absolute;
// 			bottom: 2.5rem; left: 50%;
// 			transform: translate(-50%, 0);
// 		}
// 	}
// }

@media only screen and (min-width: 769px) {
	header {
		position: fixed;
		top: 0; right: 0; left: 0;

		+ * {
			margin-top: 5rem;
		}
	}

	.page-header {
		margin-top: 5rem;

		&::after {
			margin-top: 1.75rem;
		}

		p {
			font-size: 225%;
		}
	}

	#page-intro {
		padding: 5rem 2rem;
	}

	.content-block {
		&.cols-2 {
			display: flex;
			flex-wrap: wrap;

			section {
				max-width: 100%;
				padding: 0 1.875rem 0 0;
				flex-basis: 50%;
			}
		}

		&.cols-3 {
			display: flex;
			flex-wrap: wrap;

			section {
				max-width: calc(100% / 3);
				padding: 0 2rem 0 0;
				flex-basis: calc(100% / 3);
			}
		}

		section {
			+ section {
				margin-top: 0;
			}
		}
	}

	.tiles {
		&.masonry {
			.tiles-row {
				display: grid;

				&:nth-child(2n+1) {
					grid-template-columns: calc(((100% - 1.875rem) / 3) * 2) calc((100% - 1.875rem) / 3);

					.tile {
						&:nth-child(3n+1) {
							grid-row: 1 / 3;
							grid-column: 1 / 2;
						}

						&:nth-child(3n+2) {
							width: 100%;
							grid-row: 1 / 2;
							grid-column: 2 / 3;
						}

						&:nth-child(3n+3) {
							width: 100%;
							grid-row: 2 / 3;
							grid-column: 2 / 3;
						}
					}
				}

				&:nth-child(2n+2) {
					grid-template-columns: calc((100% - 1.875rem) / 3) calc(((100% - 1.875rem) / 3) * 2);

					.tile {
						&:nth-child(3n+1) {
							width: 100%;
							grid-row: 1 / 2;
							grid-column: 1 / 2;
						}

						&:nth-child(3n+2) {
							width: 100%;
							grid-row: 2 / 3;
							grid-column: 1 / 2;
						}

						&:nth-child(3n+3) {
							grid-row: 1 / 3;
							grid-column: 2 / 3;
						}
					}
				}

				.tile {
					margin: 0.9375rem 0;
				}
			}
		}
	}

	#home {
		.hero {
			.slide {
				.caption-container {
					top: 8.625rem;
				}

				.caption {
					width: 50%;
					max-width: 100%;

					&.top-left {
						top: 0; right: auto; bottom: auto; left: 0;
						transform: translate(0, 0);
					}

					&.top-center {
						top: 0; right: auto; bottom: auto; left: 50%;
						transform: translate(-50%, 0);
					}

					&.top-right {
						top: 0; right: 0; bottom: auto; left: auto;
						transform: translate(0, 0);
					}

					&.center-left {
						top: 50%; right: auto; bottom: auto; left: 0;
						transform: translate(0, -50%);
					}

					&.center-center {
						top: 50%; right: auto; bottom: auto; left: 50%;
						transform: translate(-50%, -50%);
					}

					&.center-right {
						top: 50%; right: 0; bottom: auto; left: auto;
						transform: translate(0, -50%);
					}

					&.bottom-left {
						top: auto; right: auto; bottom: 0; left: 0;
						transform: translate(0, 0);
					}

					&.bottom-center {
						top: auto; right: auto; bottom: 0; left: 50%;
						transform: translate(-50%, 0);
					}

					&.bottom-right {
						top: auto; right: 0; bottom: 0; left: auto;
						transform: translate(0, 0);
					}
				}
			}
		}

		#car-of-the-month {
			.panel {
				display: flex;
				justify-content: space-around;
				align-items: center;

				img {
					max-width: 23.75rem;
					margin: 0;
					order: 2;
				}

				.content {
					max-width: 27rem;
					order: 1;
				}
			}
		}

		#social-feeds {
			padding-top: 5rem;
			padding-bottom: 5rem;
			align-items: flex-start;
		}

		#testimonials {
			padding-bottom: 3rem;

			.testimonials {
				width: calc(100% - 10.5rem);
				max-width: 100%;
			}

			.testimonial {
				.content {
					.quote {
						text-align: left;
					}
				}
			}

			.prev,
			.next {
				top: 50%; right: auto; bottom: auto; left: auto;
				transform: translate(0, -50%);
			}

			.prev {
				left: 2rem;
			}

			.next {
				right: 2rem;
			}
		}
	}

	.tag-results {
		font-size: 140%;
	}

	.post-container {
		.post {
			.body {
				padding-top: 3.125rem;
				padding-bottom: 3.125rem;

				.title {
					font-size: 225%;
				}

				.extract {
					p {
						font-size: 112.5%;
					}
				}
			}
		}
	}

	#dealer-applications {
		td {
			padding: 1rem;
		}
	}

	#exclusive-offer {
		padding: 5rem 1.25rem;

		.inner {
			max-width: 60rem;

			h3 {
				max-width: 50%;
				margin-bottom: 1.25rem;
				padding-left: 1.875rem;
				text-align: left;
			}

			.panel {
				position: relative;
				background: $primary;
				padding: 1.5rem 1.875rem;
			}

			figure {
				position: absolute;
				top: 50%; right: 0;
				width: 50%;
				padding: 0 1rem;
				transform: translate(0, -50%);
			}

			img {
				max-width: 28rem;
				margin: 0 auto;
			}

			.offer {
				background: transparent;
				width: 50%;
				max-width: 25rem;
				padding: 0;
				text-align: left;
			}
		}

		.valid {
			position: absolute;
			right: 0.625rem; bottom: 0.625rem;
		}
	}

	#packaging-calculator {
		padding: 2rem 1.875rem;

		.panel {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			.instruction {
				width: calc((100% - 1.875rem) / 2);
				max-width: 28.125rem;
			}

			> .option {
				width: calc((100% - 1.875rem) / 2);
			}
		}
	}
}

@media only screen and (min-width: 769px) and (max-width: 940px) {
	#home {
		#social-feeds {
			#blog {
				display: flex;

				.featured-image {
					width: 20rem;
					height: auto;
				}

				.content {
					width: calc(100% - 20rem);
					padding: 1.25rem 1.5rem;
				}
			}

			#instagram {
				#instafeed {
					.insta-tag {
						width: calc((100% - (0.625rem * 3)) / 4);
						padding-top: calc((100% - (0.625rem * 3)) / 4);
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 841px) {
	#home {
		.featured-tiles {
			display: flex;
			padding-top: 5rem;
			padding-bottom: 5rem;
			justify-content: space-between;

			.tile {
				width: calc((100% - 4rem) / 3);
				margin: 0;
				padding-top: 9.5rem;
				padding-bottom: 9.5rem;
			}
		}
	}

	.contact-form {
		gap: 5rem;

		#page-intro {
			width: calc(100% - 25rem - 5rem);
		}

		#dealers-enquiry-form {
			margin: 0;
		}
	}

	.related-posts {
		.post {
			width: calc((100% - (1.875rem * 2)) / 3);

			&:nth-child(3) {
				display: flex;
			}
		}
	}

	.deals {
		.deal {
			width: calc(100% / 3);
			padding: 3rem 2rem;
		}
	}
}

@media only screen and (min-width: 941px) {
	header {
		+ * {
			margin-top: 5.625rem;
		}

		.centraliser {
			padding-top: 0;
			padding-bottom: 0;

			&::before {
				content: none;
			}
		}

		#logo {
			max-width: 4.125rem;
			height: 3.125rem;
			margin-right: 3.125rem;
		}

		nav {
			position: initial;
			width: 100%;
			max-width: 100%;
			margin-left: 0;
			margin-right: 0;
			transform: none;
			text-align: center;

			> ul {
				display: flex;
				padding: 0;
				justify-content: center;
				align-items: center;

				> li {
					padding: 0 1.125rem;

					&.nav-item-about {
						margin-left: auto;
					}

					&::before {
						content: '';
						position: absolute;
						top: 0; right: 0; bottom: 0; left: 0;
						background: $white;
						z-index: 1;
					}

					&:hover {
						ul {
							transform: translate(0, 0);
							visibility: visible;
							opacity: 1;
						}
					}

					> a {
						display: flex;
						background: $white;
						height: 5.625rem;
						padding: 0;
						align-items: center;

						&::before {
							top: 3.75rem;
							height: 3px;
						}

						&:hover {
							color: $secondary;
						}
					}

					button {
						display: none;
					}

					ul {
						display: block;
						position: absolute;
						top: 100%; left: 0;
						background: $white;
						width: 15rem;
						padding-top: 0.75rem;
						padding-bottom: 0.75rem;
						box-shadow: 0 2px 5px rgba($black, 0.15);
						transform: translate(0, -100%);
						transition: all 0.25s ease;
						text-align: left;
						visibility: hidden;
						opacity: 0;
						z-index: 0;

						li {
							+ li {
								margin-left: 0;
							}

							a {
								height: auto;
								padding: 0.5rem 1.25rem;
							}
						}
					}
				}
			}
		}

		.search {
			margin-left: auto;
		}

		.mobile-nav {
			display: none;
		}
	}

	.landing-page {
		header {
			.centraliser {
				padding: 1.25rem;
			}
		}
	}

	footer {
		.centraliser {
			display: flex;
			padding: 3rem 1.875rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			&::before,
			&::after {
				content: none;
			}
		}

		.phone {
			margin: 0 1rem 0 0;
		}

		.email {
			margin: 0 auto 0 0;
		}

		.social {
			margin: 0 auto;
		}

		nav.footer {
			margin: 0 auto;

			ul {
				flex-direction: column;
			}
		}

		.legal {
			margin: 0 0 0 auto;
			text-align: right;
		}

		.top {
			margin: 0 0 0 3rem;
		}
	}

	.page-header {
		margin-top: 5.625rem;
	}

	.gallery {
		figure {
			width: calc(100% / 4);
		}
	}

	.tiles {
		&.hero {
			.tile {
				height: calc(100vh - 5.625rem);
			}
		}
	}

	#home {
		.hero {
			.slide {
				height: calc(100vh - 5.625rem);
			}
		}

		#testimonials {
			.testimonials {
				width: calc(100% - 10.5rem);
				max-width: 60rem;
			}

			.testimonial {
				display: flex;
				align-items: flex-start;
				flex-wrap: wrap;

				img {
					margin: 0 2rem 0 0;
				}

				.content {
					width: calc(100% - 9.5rem);

					.quote {
						text-align: left;
					}
				}
			}
		}

		#social-feeds {
			align-items: initial;

			#blog {
				display: flex;
				width: calc((100% - 3rem) * 0.6);
				max-width: 41.875rem;
				margin: 0 3rem 0 0;
				flex-wrap: wrap;

				.content {
					display: flex;
					height: calc(100% - 15.5rem);
					flex-direction: column;
					flex-wrap: wrap;

					.more {
						margin-top: auto;
					}
				}
			}

			#instagram {
				display: flex;
				width: calc((100% - 3rem) * 0.4);
				max-width: 31.25rem;

				#instafeed {
					width: 100%;

					.insta-tag {
						margin-top: 0.625rem;
						margin-bottom: 0;

						&:nth-child(-n+2) {
							margin-top: 0;
						}
					}
				}
			}
		}
	}

	.post-container {
		.post {
			max-width: 80rem;
			margin: 0 auto;
			padding-top: 5rem;
			padding-bottom: 5rem;
			justify-content: center;

			.image {
				width: calc(50% - (1.875rem * 2));
				margin-left: 1.875rem;
				order: 2;
			}

			.body {
				width: calc(50% - 1.875rem);
				padding: 1.75rem 0;
				order: 1;
			}
		}
	}

	.headless {
		#logo {
			margin-top: 1.25rem;
			margin-bottom: 1.25rem;
		}
	}

	.summary-table {
		th {
			font-size: 112.5%;
		}

		tr {
			> :nth-child(2) {
				font-size: 100%;
			}
		}
	}
}

@media only screen and (min-width: 1025px) {
	.tiles {
		&.masonry {
			.tiles-row {
				display: flex;
				width: calc((100% - 1.875rem) / 2);

				&:nth-child(2n+1) {
					.tile {
						&:nth-child(3n+2),
						&:nth-child(3n+3) {
							width: calc((100% - 1.875rem) / 2)
						}
					}
				}

				&:nth-child(2n+2) {
					.tile {
						&:nth-child(3n+1),
						&:nth-child(3n+2) {
							width: calc((100% - 1.875rem) / 2);
						}
					}
				}
			}
		}

		&.icon-grid {
			.tile {
				width: calc((100% - 6rem) / 4);
			}
		}
	}

	#packaging-calculator {
		.panel {
			.instruction {
				width: calc((100% - 4.375rem) * 0.4);
			}

			> .option {
				width: calc((100% - 4.375rem) * 0.6);
				max-width: 43.75rem;
			}
		}
	}

	.summary-table {
		tr {
			> :last-child {
				padding: 1rem 2.75rem;
			}
		}

		thead {
			tr {
				> *,
				> :last-child {
					padding-top: 2rem;
					padding-bottom: 1rem;
				}
			}
		}

		tfoot {
			tr {
				> *,
				> :last-child {
					padding-top: 1rem;
					padding-bottom: 2rem;
				}
			}
		}
	}
}

@media only screen and (min-width: 1051px) {
	#packaging-calculator {
		.industries {
			display: flex;
			flex-wrap: wrap;

			label {
				display: flex;
				width: calc((100% - 0.75rem) / 3);
				min-height: 4.625rem;
				margin-right: 0.125rem;
				margin-left: 0.125rem;
				justify-content: center;
				align-items: center;
				text-align: center;
			}
		}
	}
}

@media only screen and (min-width: 1141px) {
	header {
		nav {
			> ul {
				> li {
					padding: 0 1.5rem;

					ul {
						li {
							a {
								padding: 0.5rem 1.5rem;
							}
						}
					}
				}
			}
		}
	}

	#home {
		.featured-tiles {
			.tile {
				* {
					max-width: 18rem;
				}
			}
		}
	}
}

@media only screen and (min-width: 1281px) {
	#home {
		#testimonials {
			.prev,
			.next {
				right: auto; left: 50%;
			}

			.prev {
				transform: translate(calc(-50% - 35rem), -50%);
			}

			.next {
				transform: translate(calc(-50% + 35rem), -50%);
			}
		}
	}

	.headless {
		main {
			display: flex;
			max-width: 83.75rem;
			margin: 0 auto;

			#page-intro {
				max-width: 31.25rem;
				padding: 5rem 1.875rem;
			}

			.rem-lite-form {
				width: 100%;
				max-width: 52.5rem;
				padding: 5rem 1.875rem;
			}
		}
	}
}
