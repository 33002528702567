@mixin clearfix {
	&::before,
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin centraliser {
	position: relative;
	max-width: $max-width;
	margin: 0 auto;
	padding: 0 1rem;
}

@mixin sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0 none;
	-webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
	clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
	clip: rect(0 0 0 0);
	white-space: nowrap;
	overflow: hidden;
}
