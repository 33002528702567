// Sliders

%bv_hidden {
	backface-visibility: hidden;
}

%filled_obj {
	content: '';
	position: absolute;
	inset: 0;
}

.slider {
	max-width: 100%;
}

.main-slider {
	position: relative;
	width: 100%;
	min-height: 8vw;
	margin: 0;
	transition: all 1.25s ease;
	visibility: hidden;
	opacity: 0;

	&.slick-initialized {
		visibility: visible;
		opacity: 1;
	}

	.slick-slide {
		height: clamp(20rem, 80vw, 42rem);
	}
}

.slick-dotted.slick-slider {
	margin-bottom: 0;
}

.slick-slide {
	@extend %bv_hidden;

	position: relative;
	padding: 0.625rem;

	video {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		transform: translate(-50%, -50%);
	}

	iframe {
		position: relative;
		pointer-events: none;
	}

	figure {
		position: relative;
		height: 100%;
		overflow: hidden;
		z-index: 1;
	}

	.slide-image {
		background-position: center;
		background-size: cover;
		height: 100%;
		transition: all 0.75s ease;
		opacity: 0;

		&.show {
			opacity: 1;
		}
	}

	.image-entity {
		width: 100%;
		visibility: hidden;
		opacity: 0;
	}

	.slide-media {
		// animation: slideOut 0.5s cubic-bezier(0.4, 0.29, 0.01, 1);
	}

	&.slick-active {
		z-index: 1;

		.slide-media {
			// animation: slideIn 2.5s cubic-bezier(0.4, 0.29, 0.01, 1);
		}

		.caption {
			@extend %bv_hidden;

			position: absolute;
			background: rgba($white, 0.9);
			max-width: 21.875rem;
			margin: 0;
			padding: 0.75rem 1rem 1rem;
			transition: all 0.25s ease;
			z-index: 1;

			&.top-left {
				top: 2.5rem;
				left: 2.5rem;
			}

			&.top-center {
				top: 2.5rem;
				left: 50%;
				transform: translate(-50%, 0);
			}

			&.top-right {
				top: 2.5rem;
				right: 2.5rem;
			}

			&.center-left {
				top: 50%;
				left: 2.5rem;
				transform: translate(0, -50%);
			}

			&.center-center {
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&.center-right {
				top: 50%;
				right: 2.5rem;
				transform: translate(0, -50%);
			}

			&.bottom-left {
				bottom: 2.5rem;
				left: 2.5rem;
			}

			&.bottom-center {
				bottom: 2.5rem;
				left: 50%;
				transform: translate(-50%, 0);
			}

			&.bottom-right {
				bottom: 2.5rem;
				right: 2.5rem;
			}
		}
	}
}

.slick-dots {
	display: flex;
	position: absolute;
	right: 1.75rem;
	bottom: 1.75rem;
	width: auto;
	padding: 0;
	justify-content: center;

	li {
		width: 0.75rem;
		height: 0.75rem;
		margin: 0 0.3125rem;

		&.slick-active {
			button {
				background: $primary;
			}
		}

		&:hover {
			button {
				background: $secondary;
			}
		}

		button {
			background: $white;
			width: 0.75rem;
			height: 0.75rem;
			margin-top: 0;
			padding: 0;
			border-radius: 1rem;
			border: 1px solid $white;

			&::before {
				content: none;
			}
		}
	}
}

@keyframes slideIn {
	from {
		filter: blur(15px);
	}

	to {
		filter: blur(0);
	}
}

@keyframes slideOut {
	from {
		filter: blur(0);
	}

	to {
		filter: blur(15px);
	}
}

@media only screen and (min-width: 750px) {
	.main-slider {
		.slick-arrow {
			border-width: 7.5px;
		}
	}
}
