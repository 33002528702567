.message {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	padding: 0.75rem 0;
	text-align: center;
	z-index: 5;
	clear: both;

	p {
		margin: 0 auto;
	}

	&.negative {
		background: $negative;
		margin-bottom: 10px;
		text-align: center;
		color: $white;

		p {
			margin: 0;
		}
	}

	&.positive,
	&.success {
		background: $positive;
		color: $white;
	}
}

p.error,
span.error {
	display: block;
	background: $negative;
	width: 100%;
	margin: 0 0 1.5rem;
	padding: 0.375rem 0.625rem;
	font-size: 80%;
	color: $white;

	&:empty {
		display: none;
	}
}

p.error {
	text-align: center;
	color: $negative;
}
